<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="iconSize" :height="iconSize" viewBox="0 0 20.025 15.664">
    <g id="Component_30_1" data-name="Component 30 – 1" transform="translate(0.35 0.35)">
      <path id="Icon_open-media-play" data-name="Icon open-media-play" d="M0,0V7.619L7.619,3.809Z" transform="translate(6.822 4.38)" :fill="iconColor"/>
      <path id="Icon_feather-folder" data-name="Icon feather-folder" d="M21.242,16.193A1.753,1.753,0,0,1,19.4,17.839l-15.64-.151a1.753,1.753,0,0,1-1.843-1.646V4.521A1.753,1.753,0,0,1,3.759,2.875H8.366l1.843,2.469,9.19.151a1.753,1.753,0,0,1,1.843,1.646Z" transform="translate(-1.917 -2.875)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.7"/>
    </g>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize: Number,
      iconColor: String
    }
  }
</script>