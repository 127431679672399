<template>
  <svg id="Component_29_1" data-name="Component 29 – 1" xmlns="http://www.w3.org/2000/svg" :width="iconSize" :height="iconSize" viewBox="0 0 22.09 15.795">
    <path id="Path_2600" data-name="Path 2600" d="M1.97,21.171A1.972,1.972,0,0,1,0,19.2V7.346a1.973,1.973,0,0,1,1.97-1.97H20.12a1.974,1.974,0,0,1,1.97,1.97V19.2a1.974,1.974,0,0,1-1.97,1.97Zm0-15.087A1.264,1.264,0,0,0,.708,7.346V19.2A1.264,1.264,0,0,0,1.97,20.463l18.151,0A1.265,1.265,0,0,0,21.383,19.2V7.346A1.265,1.265,0,0,0,20.12,6.084Z" transform="translate(0 -5.376)" :fill="iconColor"/>
    <path id="Path_2601" data-name="Path 2601" d="M5.162,19.97v-5.34h.991v4.5H8.946v.839Z" transform="translate(-2.24 -9.391)" :fill="iconColor"/>
    <rect id="Rectangle_27" data-name="Rectangle 27" width="0.991" height="5.34" transform="translate(7.492 5.239)" :fill="iconColor"/>
    <path id="Path_2602" data-name="Path 2602" d="M18.464,19.97l-2.334-5.34h1.076l2.082,4.829H18.67l2.105-4.829h.991L19.44,19.97Z" transform="translate(-6.998 -9.391)" :fill="iconColor"/>
    <path id="Path_2603" data-name="Path 2603" d="M28.076,19.139h3.012v.832h-4v-5.34h3.9v.832H28.076ZM28,16.851h2.654v.816H28Z" transform="translate(-11.751 -9.391)" :fill="iconColor"/>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize: Number,
      iconColor: String
    }
  }
</script>