<template>
  <svg id="Component_31_1" data-name="Component 31 – 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="iconSize" :height="iconSize" viewBox="0 0 22.091 15.795">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_30" data-name="Rectangle 30" :width="iconSize" :height="iconSize" :fill="iconColor"/>
      </clipPath>
    </defs>
    <g id="Group_24" data-name="Group 24" clip-path="url(#clip-path)">
      <path id="Path_2610" data-name="Path 2610" d="M1.97,15.795A1.973,1.973,0,0,1,0,13.825V1.97A1.973,1.973,0,0,1,1.97,0H20.121a1.974,1.974,0,0,1,1.97,1.97V13.823a1.974,1.974,0,0,1-1.97,1.97ZM1.97.708A1.264,1.264,0,0,0,.708,1.97V13.825A1.264,1.264,0,0,0,1.97,15.087l18.151,0a1.265,1.265,0,0,0,1.262-1.262V1.97A1.265,1.265,0,0,0,20.121.708Z" transform="translate(0 0)" :fill="iconColor"/>
    </g>
    <g id="Group_808" data-name="Group 808" transform="translate(5 2)">
      <path id="Path_2723" data-name="Path 2723" d="M0,7.23V7.1A3.151,3.151,0,0,1,5.381,4.873l.263.263.245-.263a3.211,3.211,0,0,1,2.768-.882A3.152,3.152,0,0,1,11.289,7.1V7.23A3.294,3.294,0,0,1,10.24,9.643L6.256,13.363a.9.9,0,0,1-1.223,0L1.049,9.643A3.3,3.3,0,0,1,0,7.23Z" transform="translate(0 -3.02)" :fill="iconColor"/>
      <path id="Path_2724" data-name="Path 2724" d="M0,0H11.289V11.289H0Z" transform="translate(0)" fill="none"/>
    </g>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize: Number,
      iconColor: String
    }
  }
</script>