<template>
    <component :is="iframeName" />
</template>

<script>
    import LiveEvent from './LiveEvent.vue';

    export default {
        components:{
            LiveEvent,
        },
        props: {
            iframeName: String, 
        },
        data() {
            return {
                iframeName: this.iframeName,
            }
        }
    }
</script>