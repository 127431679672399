<template>
    <label id="customCheckBox" class="container" @click="toggleCheckbox">
      <input type="checkbox" :checked="localIsChecked">
      <span class="CheckBox" @click.stop></span>
    </label>
</template>
  
<script>
  export default {
    props: {
      isChecked: Boolean,
    },
    data() {
      return {
        localIsChecked: this.isChecked,
      };
    },
    methods: {
      toggleCheckbox() {
        this.localIsChecked = !this.localIsChecked;
        this.$emit("checkbox-clicked", this.localIsChecked);
      },
    },
    watch:{
      isChecked(newValue,oldValue){
        this.localIsChecked = newValue;
      }
    }
  };
</script>

<style>
  #customCheckBox {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 20px;
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    margin: 0;
  }
  #customCheckBox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .CheckBox {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 0.5px #d0d0d0 solid;
    border-radius: 5px;
  }
  .container:hover input ~ .CheckBox {
    background-color: #fff;
    }
  #customCheckBox input:checked ~ .CheckBox {
    background-color: #fff;
  }
  .CheckBox:after {
    content: "";
    position: absolute;
    display: none;
  }
  #customCheckBox input:checked ~ .CheckBox:after {
    display: block;
  }
  #customCheckBox .CheckBox:after {
    left: 7.5px;
    top: 3.5px;
    width: 5px;
    height: 10px;
    border: solid #40bbf0;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
</style>