<script>
import axios from 'axios'

export default {
  methods: {
    // Method to set Authorization header dynamically
    setAuthorizationHeader() {
      const bearerToken = localStorage.getItem('bearer_token');
      if (bearerToken) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
      } else {
        delete axios.defaults.headers.common['Authorization']; // Remove header if no token
      }
    },

    fetchAzureData(data) {
      this.setAuthorizationHeader(); // Set Authorization before the request
      return new Promise((resolve, reject) => {
        axios(`${import.meta.env.VITE_BASE_URL}/v1/token`, {
          method: 'GET',
          params: data,
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err.response.data);
          })
      });
    },
  }
}
</script>
