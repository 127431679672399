<template>
  <div class="media-group">
    <label :for="localId" :class="`imageType-${imageType}`">
      <div v-if="loading" class="loading-holder">
        <img src="@/assets/loading.gif" alt="Loading animation">
      </div>
      <img v-else-if="image" :src="image" alt="" @error="resetImage">
      <div v-else>
        <div class="line"></div>
        <div class="line"></div>
        <div class="circle"><font-awesome-icon :icon="['fas', 'plus']" /></div>
      </div>

      <div class="info-wrapper" :class="{ 'down': showGetFrameImageButton }">
        <div v-if="showAddImageButton" class="button-holder">
          <button type="button" @click="triggerImageInput">Image<span v-if="showStar" class="star"> *</span></button>
          <div class="text-holder">
            <p>Help info...</p>
          </div>
        </div>
        <div v-if="showAddBrowseButton" class="button-holder">
          <button type="button">Browse</button>
          <div class="text-holder">
            <p>Help info...</p>
          </div>
        </div>
        <div v-if="showAdditionalInfo" class="text-holder">
          <span>{{ imageTitle }}</span>
          <p>{{ imageInfo }}
          </p>
        </div>
      </div>
    </label>
    <input @change="handleImageChange" ref="imageInput" :id="localId" type="file" accept="png" hidden>
    <div v-if="showGetFrameImageButton" class="button-holder-3">
      <button type="button" @click="$emit('on-take-from-frame')">Get from frame</button>
    </div>

  </div>
</template>

<script scoped>
import { BlobServiceClient } from "@azure/storage-blob";
import azureMixin from '@/mixins/azure.vue';

export default {
  props: {
    id: String,
    path: String,
    imagePath: String,
    imageType: Number,
    azureInitialisationData: Object,
    showGetFrameImageButton: {
      default: false,
      type: Boolean
    },
    showAddImageButton: {
      default: false,
      type: Boolean
    },
    showAddBrowseButton: {
      default: false,
      type: Boolean
    },
    showAdditionalInfo: {
      default: true,
      type: Boolean
    },
    imageTitle: String,
    imageInfo: String,
    showStar:{
      type:Boolean,
      default:false,
    },

  },
  mixins: [azureMixin],
  data() {
    return {
      localId: this.generateUniqueId(),
      loading: false,
      containerName: null,
      blobServiceClient: null,
      containerClient: null,
      image: this.imagePath ?? null,
      localImagePath: this.imagePath ?? null,
      azureInitialisationDataObject: {},
    }
  },
  watch: {
    imagePath(newValue) {
      if (newValue !== this.localImagePath) {
        this.localImagePath = newValue;
        this.image = newValue;
      }
    },
  },
  methods: {
    generateUniqueId() {
      return `id-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    },
    resetImage() {
      this.image = null;
      this.localImagePath = null;
    },
    triggerImageInput() {
      const imageInput = this.$refs.imageInput;
      if (imageInput) {
        // Programmatically trigger the click event to open the file dialog
        imageInput.click();

        // When the file dialog is closed, the @change event will be triggered
        // No need to manually trigger it, as it will happen automatically
      }
    },
    async getMediaBlob(path, file) {
      const fileNameBase = file.name.match(/^.*?([^\\/.]*)[^\\/]*$/)[1];
      const fileName = fileNameBase.replace(/[ &\/\\#,+()$~%.'":*?<>{}]/g, "-");
      const filenameWithExtension = file.name.split('.').pop();

      const KeyFileName = `${fileName}-${Date.now()}.${filenameWithExtension}`;

      return path + KeyFileName;
    },

    async handleImageChange(event) {
      if (event.target.files.length === 0) {
        return;
      }

      this.loading = true;
      const file = event.target.files[0];
      const blobName = await this.getMediaBlob(this.path, file);
      const promises = [];

      this.$emit('image-blob-name', blobName);

      try {
        const blockBlobClient = this.containerClient.getBlockBlobClient(blobName);
        promises.push(blockBlobClient.uploadBrowserData(file, {
          blobHTTPHeaders: {
            blobContentType: file.type
          }
        })
        );

        await Promise.all(promises);
      } catch (error) {
        this.loading = false;
        this.store.dispatch('snack/error', "Upload failed");

        return;
      }

      this.loading = false;
      this.localImagePath = blobName;
      this.image = URL.createObjectURL(file);

      const dataToSend = {
        image: this.image,
        imagePath: this.localImagePath,
      }

      this.$emit('on-change', dataToSend);
    },
    async fetchAzureInitialisationDataHandler() {
      await this.fetchAzureData()
        .then(res => {
          if (res?.data?.data) {
            this.azureInitialisationDataObject = res.data.data;
          }
        })
        .catch(error => { });
    },
  },
  mounted() {
    this.fetchAzureInitialisationDataHandler()
      .then(() => {
        this.containerName = this.azureInitialisationDataObject?.container ?? null;
        this.blobServiceClient = new BlobServiceClient(this.azureInitialisationDataObject?.blobSasUrl ?? null);
        this.containerClient = this.blobServiceClient.getContainerClient(this.containerName);
      });
  }
}
</script>

<style>

.star{
  color: red;
}

.info-wrapper {
  position: static !important;
  background: transparent !important;
  height: auto !important;
  min-height: 500px !important;
  max-height: 501px !important;
  margin-inline: auto;
  width: 200px;
}

.down {
  margin-top: 45px;
}

.media-group {
  display: flex;
  /* justify-content: ; */
  align-items: center;
  flex-direction: column;
  min-height: 330px;
  margin-bottom: 50px;
}

.media-group .imageType-1 {
  width: 104px;
  height: 225px;
  min-width: 104px !important;
}

.media-group .imageType-2 {
  width: 104px;
  height: 71px;
  min-width: 104px !important;
}

.media-group .imageType-3 {
  width: 100px;
  height: 50px;
  min-width: 104px !important;
}
.media-group .imageType-4 {
  width: 220px !important;
  height: 124px !important;
  min-width: 104px !important;
}

.media-group label {
  max-width: 100%;
  border-radius: 8px;
  /* overflow: hidden; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
  margin-right: 0px !important;
  position: relative;
}

.media-group .loading-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-group .loading-holder img {
  max-width: 50px;
  max-height: 50px;
}

.media-group label img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media-group label div {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f9f9f9;
}

.media-group label div .line {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 1.5px;
  background-color: #d0d0d0;
  margin: auto;
}

.media-group label div .line:nth-child(1) {
  transform: rotate(30deg);
  z-index: 1;
}

.media-group label div .line:nth-child(2) {
  transform: rotate(-30deg);
  z-index: 2;
}

.media-group label div .circle {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 100%;
  border: 1px solid #d0d0d0;
  color: #d0d0d0;
  font-weight: bold;
  font-size: 24px;
  background-color: #ffffff;
  z-index: 3;
}

.media-group .text-holder {
  max-width: 200px;
  /* margin-left: 20px; */
  background: transparent;
  margin: 0 !important;
  margin-inline: auto;


}

.media-group .text-holder span {
  display: block;
  font-size: 1rem;
  margin-top: 20px;
  margin-bottom: 1rem;
  text-align: center;
}

.media-group .text-holder p {
  font-size: 10px;
  color: #666666;
  text-align: center;
  width: 100%;
}

.media-group button {
  width: 200px;
  color: #464545;
  text-decoration: none;
  border-radius: 6px;
  font-size: 15px;
  padding: 8px 0px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.button-holder {
  background: transparent !important;
}

.button-holder-3 {
  z-index: 20;
}
</style>