<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="iconSize" :height="iconSize" viewBox="0 0 24.351 22.555">
    <path id="Icon_ionic-md-home" data-name="Icon ionic-md-home" d="M12.356,26.055V18.87h5.389v7.185h5.479V15.278h3.5L15.051,4.5,3.375,15.278h3.5V26.055Z" transform="translate(-2.875 -4)" fill="none" :stroke="iconColor" stroke-linejoin="round" stroke-width="1"/>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize: Number,
      iconColor: String
    }
  }
</script>