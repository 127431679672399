<template>
  <div class="snackbar" :class="snackbarType">
    {{ message }}
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'Snackbar',
  setup() {
    const store = useStore();
    
    const message = computed(() => store.getters['snack/message']);
    const snackbarType = computed(() => store.getters['snack/type']);

    return {
      message,
      snackbarType
    };
  }
};
</script>

<style scoped>
.snackbar {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  z-index: 9999;
  transition: opacity 0.3s;
}

.success {
  background-color: green;
}

.error {
  background-color: red;
}

.warning {
  background-color: orange;
}

@media screen and (max-width: 600px) {
  .snackbar {
    width: max-content;
    max-width: 90vw;
    left: 0;
    right: 0;
    margin: auto;
    transform: unset;
    /* margin: 0px 2rem; */
    text-align: center;
  }
}
</style>
