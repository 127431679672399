
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

export default {
    setup() {
        const store = useStore();
        const metaTitle = computed(() => store.state.playerData?.seo_data.meta_title ?? 'Default Title');
        const metaDescription = computed(() => store.state.playerData?.seo_data.meta_desc ?? 'Default desc');
        const metaImage = computed(() => import.meta.env.VITE_AZURE_BASE_URL+store.state.playerData?.image_data.default ?? 'Default image');
        const metaKey = computed(() => store.state.playerData?.seo_data.meta_key ?? 'Default key');
        useMeta({
            title: `${metaTitle.value}`,
            description: `${metaDescription.value}`,
            image:`${metaImage.value}`,
            key: `${metaKey.value}`,
            url: window.location.href,
            // htmlAttrs: {
            //     lang: 'en',
            //     amp: true
            // },
            meta: [
                { property: 'og:title', content: `${metaTitle.value}` },
                { property: 'og:description', content: `${metaDescription.value}` },
                { property: 'og:image', content: `${metaImage.value}` },
                { property: 'og:url', content: window.location.href },
                { property: 'og:type', content: 'website' },
                
            ],
        })
        return {
            store,
            metaTitle,
            metaDescription,
            metaImage,
            metaKey,
            useMeta,
        }
    },
    beforeMount(){
        useMeta()
    }
}
</script>

<template>
</template>

<style scoped></style>