<template>
  <svg :width="iconSize" :height="iconSize" viewBox="0 0 19 18.037">
    <g id="Group_545" data-name="Group 545" transform="translate(-581.193 -132.5)">
      <g id="Group_2" data-name="Group 2" transform="translate(581.693 133)">
        <path id="Icon_material-event-note" data-name="Icon material-event-note" d="M19.171,2.662h-.852V.958h-1.7v1.7H7.134V.958h-1.7v1.7H4.579a1.7,1.7,0,0,0-1.7,1.7L2.875,16.292a1.7,1.7,0,0,0,1.7,1.7H19.171a1.709,1.709,0,0,0,1.7-1.7V4.366a1.709,1.709,0,0,0-1.7-1.7Z" transform="translate(-2.875 -0.958)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-width="1"/>
      </g>
      <line id="Line_1" data-name="Line 1" x2="3" transform="translate(584.193 139.741)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-width="1"/>
      <line id="Line_1-2" data-name="Line 1" x2="3" transform="translate(589.085 139.741)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-width="1"/>
      <line id="Line_1-3" data-name="Line 1" x2="3" transform="translate(593.943 139.741)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-width="1"/>
      <line id="Line_1-4" data-name="Line 1" x2="3" transform="translate(584.193 143.296)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-width="1"/>
      <line id="Line_1-5" data-name="Line 1" x2="3" transform="translate(589.085 143.296)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-width="1"/>
      <line id="Line_1-6" data-name="Line 1" x2="3" transform="translate(593.943 143.296)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-width="1"/>
      <line id="Line_1-7" data-name="Line 1" x2="3" transform="translate(584.193 146.454)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-width="1"/>
      <line id="Line_1-8" data-name="Line 1" x2="3" transform="translate(589.085 146.454)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-width="1"/>
      <line id="Line_1-9" data-name="Line 1" x2="3" transform="translate(593.943 146.454)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-width="1"/>
    </g>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>