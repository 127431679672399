<template>
  <aside id="side-menu" :class="{'show': isSideMenuOpen}">
    <div class="image-holder">
      <img :src="logoPath" alt="Logo">
    </div>
    
    <nav>   
      <router-link @click="closeMenu" :to="{ name: 'home'}"><div class="icon-holder"><Icon iconName="IconPodcast"/></div> Home </router-link>
      <router-link @click="closeMenu" :to="{ name: 'events'}"><div class="icon-holder"><Icon iconName="IconEvents"/></div> Events </router-link>
      <div class="dropdown">
        <input type="checkbox" id="media-toggle" checked>
        <label for="media-toggle" class="dropdown-btn"><div class="icon-holder"><Icon iconName="IconMedia"/></div> Media</label>
        <div class="links-holder">
          <router-link @click="closeMenu" :to="{ name: 'media'}"><div class="icon-holder"></div>Media List</router-link>
          <router-link @click="closeMenu" :to="{ name: 'media.categories'}"><div class="icon-holder"></div>Media Categories</router-link>
          <router-link @click="closeMenu" :to="{ name: 'media.order'}"><div class="icon-holder"></div>Media Order</router-link>
          <router-link @click="closeMenu" :to="{ name: 'media.add'}"><div class="icon-holder"></div>Add Media</router-link>
        </div>
      </div>
      <div class="dropdown">
        <input type="checkbox" id="ads-toggle" checked>
        <label for="ads-toggle" class="dropdown-btn"><div class="icon-holder"><Icon iconName="IconAds"/></div> Ads</label>
        <div class="links-holder">
          <!-- <router-link @click="closeMenu" :to="{ name: 'ads'}"><div class="icon-holder"></div>Ads</router-link> -->
          <router-link @click="closeMenu" :to="{ name: 'ads.graphic'}"><div class="icon-holder"></div>Graphic Ads</router-link>
          <router-link @click="closeMenu" :to="{ name: 'ads.video'}"><div class="icon-holder"></div>Video Ads</router-link>
          <router-link @click="closeMenu" :to="{ name: 'ads.add'}"><div class="icon-holder"></div>Manage Ads</router-link>
          <!-- <router-link @click="closeMenu" :to="{ name: 'ads.types'}"><div class="icon-holder"></div>Ad Types</router-link> -->
        </div>
      </div>
      <!-- <router-link @click="closeMenu" to=""><div class="icon-holder"><font-awesome-icon :icon="['fas', 'rectangle-ad']" /> </div> Ads </router-link> -->
      <router-link @click="closeMenu" :to="{ name: 'podcasts'}"><div class="icon-holder"><Icon iconName="IconPodcast"/> </div> Podcasts </router-link>
      <router-link @click="closeMenu" :to="{ name: 'notes' }"><div class="icon-holder"><Icon iconName="IconSermonNotes"/> </div>Notes </router-link>
      <router-link @click="closeMenu" :to="{ name: 'schedule' }"><div class="icon-holder"><Icon iconName="IconSchedule"/> </div> Schedule </router-link>
      <router-link @click="closeMenu" :to="{ name: 'reports' }"><div class="icon-holder"><Icon iconName="IconReports"/> </div> Reports </router-link>
      <router-link @click="closeMenu" :to="{ name: 'form.builder' }"><div class="icon-holder"><font-awesome-icon :icon="['fas', 'hammer']" /> </div> Form Builder </router-link>
      <div class="dropdown">
        <input type="checkbox" id="settings-toggle" checked>
        <label for="settings-toggle" class="dropdown-btn"><div class="icon-holder"><Icon iconName="IconSettings"/></div> Settings</label>
        <div class="links-holder">
          <router-link @click="closeMenu" :to="{ name: 'settings.organization.details'}"><div class="icon-holder"></div>Organization Details</router-link>
          <router-link @click="closeMenu" :to="{ name: 'settings.links'}"><div class="icon-holder"></div>Links</router-link>
          <router-link @click="closeMenu" :to="{ name: 'settings.seo'}"><div class="icon-holder"></div>SEO</router-link>
          <router-link @click="closeMenu" :to="{ name: 'settings.users'}"><div class="icon-holder"></div>Users</router-link>
          <router-link v-if="user?.role === 1" @click="closeMenu" :to="{ name: 'settings.organizations'}"><div class="icon-holder"></div>Organizations</router-link>
          <router-link @click="closeMenu" :to="{ name: 'settings.graphics'}"><div class="icon-holder"></div>Graphics</router-link>
          <router-link @click="closeMenu" :to="{ name: 'settings.rebroadcast'}"><div class="icon-holder"></div>Rebroadcast</router-link>
          <router-link @click="closeMenu" :to="{ name: 'settings.player.settings'}"><div class="icon-holder"></div>Player Settings</router-link>
          <router-link @click="closeMenu" :to="{name: 'settings.layouts'}"><div class="icon-holder"></div>Layouts</router-link>
        </div>
      </div>
    </nav>

    <div class="bottom-box">
      <button class="logout-btn" @click="logout"><font-awesome-icon :icon="['fas', 'right-from-bracket']" />Logout</button>
    </div>
  </aside>
</template>

<script scoped>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

import baseImage from '@/assets/media/base-image.png';

import Icon from './Icon.vue'

export default {
  components:{
    Icon
  },
  methods: {
    isLinkActive(routeName) {
      return this.$route.name === routeName;
    },
  },
  setup() {
		const store = useStore();
    const router = useRouter();

    const isSideMenuOpen = computed(() => store.state.isSideMenuOpen);
    const user = computed(() => store.state.user);

    const logoPath = computed(() => {
      return store.state.user.organization.image_data?.logo ? 'https://cdn.vlifetech.com/' + store.state.user.organization.image_data?.logo : baseImage;
    });

    const closeMenu = () => {
      store.commit('closeSideMenu');
    };

    const logout = async () => {
      store.commit('closeSideMenu');

      store.dispatch('logOutUser');
      router.push({ name: 'login' });
    };

    return {
      user,
      logoPath,
      logout,
      isSideMenuOpen,
      closeMenu,
    };
  },
};
</script>

<style scoped>
#side-menu {
  display: flex;
  flex-direction: column;
  width: var(--side-menu-width);
  min-width: var(--side-menu-width);
  height: calc(100dvh - (var(--header-height) / 2));
  /* transform: translateY(-65px); */
  background-color: white;
  border-radius: 0px 15px 0px 0px;
}

#side-menu .image-holder {
  min-width: calc(var(--side-menu-width) - 60px);
  min-height: calc(var(--side-menu-width) - 60px);
  max-width: calc(var(--side-menu-width) - 60px);
  max-height: calc(var(--side-menu-width) - 60px);
  margin: 30px;
  border-radius: 20px;
  overflow: hidden;
}

#side-menu .image-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#side-menu nav {
  height: 100%;
  overflow-y: auto;
}

#side-menu nav a,
#side-menu nav .dropdown-btn {
  position: relative;
  display: block;
  color: #949494;
  text-decoration: none;
  position: relative;
  border-radius: 6px;
  font-size: 15px;
  padding: 8px 0px;
  margin: 0px 10px;
}

#side-menu nav a,
#side-menu nav .dropdown label{
  display: flex;
  align-items: center;
}


#side-menu nav .dropdown-btn {
  margin: 0px 10px 0px 10px;
}

#side-menu .dropdown:has(input[type="checkbox"]:checked) .links-holder {
  margin: 10px 0px;
}

#side-menu nav .dropdown-btn::before {
  position: absolute;
  content: '›';
  color: #464545;
  left: 14px;
  margin: auto;
  font-size: 15px;
  font-weight: bold;
  transform-origin: center;
  transition: all 0.1s ease;
}

.dropdown:has(input[type="checkbox"]:checked) .dropdown-btn::before {
  transform: rotate(90deg);
}

.dropdown:has(input[type="checkbox"]:checked) .icon-holder svg :deep(path),
.dropdown:has(input[type="checkbox"]:checked) .icon-holder svg :deep(line),
.dropdown:has(input[type="checkbox"]:checked) .icon-holder svg :deep(g){
  stroke: #40bbf0;
}

.dropdown:has(input[type="checkbox"]:checked) .icon-holder svg :deep(#Icon_open-media-play),
.dropdown:has(input[type="checkbox"]:checked) .icon-holder svg :deep(#Rectangle_11){
  fill: #40bbf0;
}

#side-menu nav .dropdown input[type="checkbox"] {
  display: none;
}

#side-menu nav .dropdown div {
  display: none;
}

#side-menu nav .dropdown input[type="checkbox"]:checked ~ div {
  display: block;
}



/* #side-menu .dropdown:has(input[type="checkbox"]:checked) .dropdown-btn{
  background-color: #ececec;
  cursor: pointer;
  color: #000;
} */
.router-link-active{
  background-color: #ececec;
  cursor: pointer;
  color: #000!important;
}

.router-link-active svg :deep(path),
.router-link-active svg :deep(g),
.router-link-active svg :deep(line){
    stroke: #40bbf0;
}

.router-link-active svg :deep(#Path_18){
  fill:#40BBF0;
}

#side-menu .dropdown .dropdown-btn:hover,
#side-menu nav a:hover {
  background-color: #f6f6f6;
  cursor: pointer;
}

#side-menu nav .dropdown a {
  font-size: 15px;
  padding: 2px 0px;
}

#side-menu nav a .icon-holder,
#side-menu nav .dropdown-btn .icon-holder {
  display: inline-block;
  width: 40px;
  text-align: center;
  margin-left: 20px;
}

#side-menu nav .icon-holder svg {
  font-size: 18px;
}

#side-menu .bottom-box {
  margin: 30px 10px;
  text-align: center;
}

#side-menu .bottom-box .logout-btn {
  padding: 10px 26px;
  background: #40BBF0;
  box-shadow: 0px 3px 6px #00000036;
  border-radius: 6px;
  transition: transform .1s;
  font-size: 18px;
  color: #FBFBFB;
  outline: none;
  border: none;
  cursor: pointer;
}

.logout-btn:hover {
  transform: scale(1.1);
  font-weight: 600;
}

#side-menu .bottom-box .logout-btn svg {
  margin-right: 10px;
}

@media only screen and (max-width: 1000px) {
  #side-menu {
    width: 100%;
    display: none;
    position: absolute;
    border-radius: 0px;
    z-index: 20;
  }

  #side-menu.show {
    display: flex;
  }
}

@media only screen and (max-width: 500px) {
  #side-menu .image-holder {
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
  }
}
</style> 