<template>
  <svg :width="iconSize" :height="iconSize" viewBox="0 0 19.065 19">
    <path id="Path_5" data-name="Path 5" d="M13.772,10.262A2.78,2.78,0,0,0,11,11.787a2.633,2.633,0,0,0,.545,3.055,2.825,2.825,0,0,0,3.143.529,2.675,2.675,0,0,0,1.569-2.7A2.735,2.735,0,0,0,13.772,10.262Zm6.669,2.678a6.981,6.981,0,0,1-.069.875l1.957,1.49a.447.447,0,0,1,.106.579L20.585,19a.473.473,0,0,1-.568.193l-1.943-.758a.708.708,0,0,0-.655.072,7.863,7.863,0,0,1-.932.529.665.665,0,0,0-.383.507l-.291,2.014a.471.471,0,0,1-.462.386h-3.7a.475.475,0,0,1-.462-.372l-.291-2.01a.68.68,0,0,0-.392-.516,6.731,6.731,0,0,1-.928-.529.713.713,0,0,0-.651-.072L6.983,19.2a.478.478,0,0,1-.568-.193L4.565,15.9a.442.442,0,0,1,.106-.579l1.652-1.261a.662.662,0,0,0,.258-.592c-.014-.175-.023-.35-.023-.525s.009-.35.023-.52a.669.669,0,0,0-.263-.588L4.666,10.571a.446.446,0,0,1-.1-.574L6.415,6.883a.473.473,0,0,1,.568-.193l1.943.758a.708.708,0,0,0,.655-.072,7.253,7.253,0,0,1,.932-.525A.679.679,0,0,0,10.9,6.34l.291-2.014a.471.471,0,0,1,.462-.386h3.7a.475.475,0,0,1,.462.372l.291,2.01a.68.68,0,0,0,.392.516,6.731,6.731,0,0,1,.928.529.713.713,0,0,0,.651.072l1.943-.763a.478.478,0,0,1,.568.193l1.851,3.114a.442.442,0,0,1-.106.579l-1.652,1.261a.677.677,0,0,0-.263.592A5,5,0,0,1,20.442,12.94Z" transform="translate(-3.967 -3.44)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
  </svg>
</template>
<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>