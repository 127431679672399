<template>
  <div id="header">
    <div class="row top">
      <div class="logo-holder">
        <router-link :to="{name: 'home'}"><img src="@/assets/media/header-logo.png" alt="Logo"></router-link>
      </div>
      <div class="auth">
        <h4><span class="space"><strong>V</strong>LIFE:</span>Reach People Where they are</h4>
      </div>
      <div class="button-holder">
        <button type="button" @click="toggleMenu" class="open-meunu-btn">
          <font-awesome-icon v-if="menuOpen" :icon="['fas', 'xmark']" />
          <font-awesome-icon v-else :icon="['fas', 'grip-lines']" />
        </button>
      </div>
    </div>
    <div class="row bottom">
      <h4 class="navigation-path">
        <span v-for="(path, index) in pathArray" :key="path">
          <template v-if="index !== 0"> / </template>
          {{ path }}
        </span>
      </h4>
      <div class="buttons-holder">

      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  name: 'Header',
  setup() {
    const store = useStore();
    const route = useRoute();

    const menuOpen = computed(() => store.state.isSideMenuOpen);

    const pathArray = computed(() => {
      let newPathArray = [];
      const routeExists = !!route.name;
      
      if (routeExists) {
        if (route.path === '/') {
          newPathArray.push("welcome");
        } else if (route.name === 'notes' || route.name === 'notes.edit') {
          newPathArray = route.path.split('/');
          newPathArray.shift();

          newPathArray[0] = "Notes"
        } else {
          newPathArray = route.path.split('/');
          newPathArray.shift();
        }
      }

      return newPathArray;
    });

    const user = computed(() => store.state.user);
    const isAuthenticated = computed(() => store.state.isAuthenticated);

    const toggleMenu = () => {
      store.commit('toggleSideMenu');
    };

    return {
      user,
      isAuthenticated,
      pathArray,
      menuOpen,
      toggleMenu,
    };
  }
}
</script>

<style scoped>
#header {
  background-color: #ededed;
  height: var(--header-height);
}

#header .row.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

#header .logo-holder {
  padding: 5px 0;
  height: 45px;
}

#header .logo-holder img {
  height: 100%;
}

#header .auth {
  color: #464545;
  font-family: 'Century Gothic';
  font-size: 20px;
  text-transform: capitalize;
}

#header .auth .space {
  letter-spacing: 18px;
}

#header .auth .space strong {
  font-weight: bold;
}

#header .row {
  height: calc(var(--header-height) / 2);
  display: flex;
  align-items: center;
}

#header .row.bottom {
  padding-left: calc(var(--side-menu-width) + 40px);
}

#header .row.bottom .navigation-path span {
  font-size: 14px;
}

#header .row.bottom .navigation-path span:first-child {
  font-size: 30px;
  text-transform: capitalize;
}

#header .row.bottom .navigation-path span:not(:first-child) {
  text-transform: capitalize;
}

#header .button-holder {
  display: none;
}

#header .open-meunu-btn {
  border: none;
  outline: none;
  font-size: 20px;
  cursor: pointer;
  background-color: transparent;
  color: #000;
}

@media only screen and (max-width: 1000px) {
  #header .auth {
    display: none;
  }

  #header .row.bottom {
    padding-left: 2rem;
  }

  #header .button-holder {
    display: block;
  }
}
</style>