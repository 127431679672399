<template>
  <div v-if="targetDate && timerTypeModern" class="timer" :style="{
    borderColor: countdownBorderColor,
    backgroundColor: countdownBackground,
    color: countdownColor,
    fontFamily: countdownFont,
  }">
    <ul>
      <li><span class="countdown-value" id="days">{{ days }}</span>days</li>
      <span class="dots">:</span>
      <li><span class="countdown-value" id="hours">{{ hours }}</span>Hours</li>
      <span class="dots">:</span>
      <li><span class="countdown-value" id="minutes">{{ minutes }}</span>Minutes</li>
      <span class="dots">:</span>
      <li><span class="countdown-value" id="seconds">{{ seconds }}</span>Seconds</li>
    </ul>
  </div>
  <div v-else-if="targetDate && !timerTypeModern" class="modern-timer" :style="{
    borderColor: countdownBorderColor,
    backgroundColor: countdownBackground,
    color: countdownColor,
    fontFamily: countdownFont,
  }">
    <ul>
      <li><span class="countdown-value" id="days">{{ days }}</span>days</li>
      <span class="slash">/</span>
      <li><span class="countdown-value" id="hours">{{ hours }}</span>Hours</li>
      <span class="slash">/</span>
      <li><span class="countdown-value" id="minutes">{{ minutes }}</span>Minutes</li>
      <span class="slash">/</span>
      <li><span class="countdown-value" id="seconds">{{ seconds }}</span>Seconds</li>
    </ul>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import axios from '../js/axios.js';

const router = useRouter();

const organization = ref(router.currentRoute.value.params.organization);
const targetDate = ref(null);
const timerTypeModern = ref(null);

const countdownBorderColor = ref('#000000');
const countdownBackground = ref('#ffffff');
const countdownColor = ref('#000000');
const countdownFont = ref('Inter');

const delay = ref(0);

const days = ref(0);
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);

// Function to update the countdown timer display
function updateCountdownDisplay(countdownValues) {
  days.value = countdownValues.days;
  hours.value = countdownValues.hours;
  minutes.value = countdownValues.minutes;
  seconds.value = countdownValues.seconds;
}

// Function to start the countdown timer
function startCountdownTimer(targetDate) {
  const countdownInterval = setInterval(() => {
    const now = new Date().getTime();
    const timeRemaining = targetDate - now;

    if (timeRemaining <= 0) {
      clearInterval(countdownInterval);
      // props.liveEventStarted(true);
      // Optionally, you can perform any action when the countdown reaches zero here.
      // For example, showing a message or disabling functionality.
      // You can also consider updating the timer display to show "Expired" or "Time's up."
    } else {
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      updateCountdownDisplay({ days, hours, minutes, seconds });
    }
  }, delay); // Update every second

  delay.value = 1000;
}

const fetchEvent = async () => {
  await axios.get(`/v1/${organization.value}/etimer`)
    .then(async response => {
      if (response.data.status === 'success') {
        targetDate.value = new Date(response.data.data.event_schedule?.start_datetime).getTime();

        countdownBorderColor.value = response.data.data.event_schedule?.external_timer_data?.color;
        countdownBackground.value = response.data.data.event_schedule?.timer_data?.background;
        countdownColor.value = response.data.data.event_schedule?.external_timer_data?.color;
        timerTypeModern.value = !!response.data.data.event_schedule?.timer_data?.type;
        countdownFont.value = response.data.data.event_schedule?.external_timer_data?.font_family;
      }
    });
};

const props = defineProps({
  colors: Object,
  startDate: String,
  liveEventStarted: Function
});


const updateTimer = () => {
  targetDate.value = new Date(props.startDate).getTime();

  countdownBorderColor.value = props.colors.border_color;
  countdownBackground.value = props.colors.background;
  countdownColor.value = props.colors.color;
};

onMounted(async () => {
  await fetchEvent();
  if (props.startDate)
    updateTimer()

  startCountdownTimer(targetDate.value);
});
</script>

<style scoped>
.timer {
  border: 1px solid;
  border-radius: 6px;
  width: fit-content;
  max-height: 100px;
}

.timer {
  display: flex;
  /* height: 100%; */
}

.timer li {
  position: relative;
  display: inline-block;
  font-size: 0.7em;
  list-style-type: none;
  padding: 1em;
  text-transform: capitalize;
  text-align: center;
}

.timer li span {
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.timer .countdown {
  min-width: 250px;
}

.timer .countdown.one {
  margin-right: 20px;
}

.timer .countdown.one .timer {
  border: 1px solid;
  border-radius: 6px;
}

.timer .countdown.one .timer li:not(:last-child)::after {
  content: ":";
  position: absolute;
  top: 0.5rem;
  right: -2px;
  margin-left: 1em;
  font-size: 1.5rem;
}

.timer .countdown.two .timer li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-right: 1px solid;
  right: -2px;
  height: 3.5rem;
  margin: auto;
  margin-left: 1em;
  font-size: 1.5rem;
  transform: rotate(15deg);
}

.modern-timer {
  width: fit-content;
  max-height: 100px;
  display: flex;
  padding: 10px 20px;
}

.modern-timer>ul {
  width: fit-content;
  max-height: 100px;
  display: flex;
  align-items: center;
}

.modern-timer>ul>li {
display: flex;
flex-direction: column;
font-size: 10px;
align-items: center;
text-transform: capitalize;
font-weight: 600;
}
.modern-timer>ul>li>span {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
}


.slash{
  position: relative;
  top: 8px;
  font-size: 49px;
  margin: 10px;
}

.dots {
  font-size: 34px;
  position: relative;
  top: -10px;
}</style>