<template>
  <ul>

    <li v-for="subcategory in subcategories" :key="subcategory.id">
      <button v-if="subcategory?.subcategories.length > 0" type="button" class="btn toggle-btn"
        @click="toggleSubcategories(subcategory)">
        <font-awesome-icon :icon="subcategory.showSubcategories ? ['fas', 'minus'] : ['fas', 'chevron-down']" />
      </button>
      <!-- <div v-else class="space"></div> -->
      <label :for="'subcategory_' + subcategory.id">
        <div class="checkbox-span" v-if="subcategory?.subcategories.length == 0">
          <img v-if="selectedCategories.includes(subcategory.id)" src="@/assets/layouts/icons/check.svg" alt="ok">
        </div>
      </label>
      <label v-if="subcategory.subcategories.length == 0" :for="'subcategory_' + subcategory.id">
        <input type="checkbox" :id="'subcategory_' + subcategory.id" :value="subcategory.id"
          :checked="selectedCategories.includes(subcategory.id)" @change="handleCategoryChange(subcategory.id)">
        {{ subcategory.name }}
      </label>
      <label v-else :for="'subcategory_' + subcategory.id"> {{ subcategory.name }} </label>

      <NestedSubcategories
        v-if="subcategory.subcategories && subcategory.subcategories.length > 0 && subcategory.showSubcategories"
        :subcategories="subcategory.subcategories" v-model="selectedCategories" />
    </li>
  </ul>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from 'vue';

export default defineComponent({
  name: 'NestedSubcategories',
  props: {
    subcategories: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedCategories = ref(props.modelValue ?? []);

    const handleCategoryChange = (categoryId) => {
      const index = selectedCategories.value.indexOf(categoryId);
      if (index > -1) {
        selectedCategories.value.splice(index, 1);
      } else {
        selectedCategories.value.push(categoryId);
      }
    };

    const toggleSubcategories = (category) => {
      category.showSubcategories = !category.showSubcategories;
    };

    watch(
      () => {
        selectedCategories.value = props.modelValue ?? [];
      },
      (newValue) => {
        emit('update:modelValue', newValue);
      }
    );

    return {
      selectedCategories,
      handleCategoryChange,
      toggleSubcategories
    };
  },
});
</script>

<style scoped>
ul {
  margin-left: 20px;
  list-style: none;
}

ul li {
  margin-top: 4px;
}

ul input[type="checkbox"] {
  margin-right: 6px;
  display: none;
}

.space {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.toggle-btn {
  width: 10px;
  height: 10px;
  outline: none;
  border: none;
  background-color: transparent;
  margin-right: 10px;
  cursor: pointer;
}

.checkbox-span {
  display: inline-block;
  position: relative;
  border: 1px solid #D0D0D0;
  width: 18px;
  height: 18px;
  margin-right: 4px;
  border-radius: 3px;
  cursor: pointer;
}

.checkbox-span>img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

</style>