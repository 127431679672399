<template>
  <div class="page-view">
    <div class="title-row">
      <h4>{{ pageTitle }}</h4>
      <slot name="custom-icon"></slot>
    </div>

    <div>
      <slot name="check-box"></slot>
    </div>

    <div class="content">
      <slot name="custom-content"></slot>
    </div>

    <div v-if="!hideActions" class="action-holder">
      <div class="button-wrapper">
        <button type="button" @click="cancelHandler">cancel</button>
      </div>
      <div class="button-wrapper">
        <button type="submit" @click="saveHandler">save</button>
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  props: {
    pageTitle: String,
    hideActions: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    cancelHandler() {
      this.$emit("on-cancel")
    },
    saveHandler() {
      this.$emit("on-save")
    }
  },
}
</script>

<style>
.page-view {
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  min-height: 650px;
  border-radius: 4px;
  border: 0.5px solid #D0D0D0;
  padding: 10px 20px;
  box-shadow: 0px 0px 6px #0000000A;
  background: #fff;
}

/* Title Row */
.page-view .title-row {
  display: flex;
  justify-content: space-between;
  color: #d0d0d0;
}

.page-view .title-row h4 {
  width: 100%;
  height: 30px;
  margin-right: 20px;
  border-bottom: 1px solid #d0d0d0;
  font-size: 16px;
}

.page-view .title-row svg {
  font-size: 49px;
}

/* Content */
.page-view .content {
  position: relative;
  min-height: 550px;
  margin: 40px 0px;
}

.page-view .content .two-col {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.page-view .content .left-col {
  width: 60%;
}

.page-view .content .right-col {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-view .content .row {
  display: flex;
}

.page-view .content .row:not(:last-child) {
  margin-bottom: 14px;
}

/* .page-view .content .row label {
  position: relative;
  min-width: 170px;
  text-align: right;
  margin-right: 14px;
  font-size: 13px;
  color: #464545;
}

.page-view .content .row label.required:after {
  content: "*";
  margin-left: 4px;
  color: #ef2d2d;
} */

/* .page-view .content textarea,
.page-view .content input[type="text"] {
  width: 100%;
  min-width: 384px;
  padding: 8px 12px;
  outline: none;
  border-radius: 4px;
  box-shadow: 0 0 6px #0000000a;
  border: 0.5px solid #ECECEC;
  font-size: 13px;
}

.page-view .content textarea:hover,
.page-view .content textarea:focus,
.page-view .content input[type="text"]:focus,
.page-view .content input[type="text"]:hover {
  box-shadow: 0 0 6px #88d3f394;
  border: 0.3px solid #A9DDF3;
} */

.page-view .content textarea {
  resize: vertical;
  height: 80px;
}

.page-view .action-holder {
  z-index: 10000;
  width: max-content;
  /* position: sticky; */
  position: fixed;
  bottom: 30px;
  left: var(--side-menu-width);
  right: 0;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: auto;
  /* border-radius: 4px; */
  /* border: 0.5px solid #D0D0D0; */
  /* padding: 10px 20px; */
  /* box-shadow: 0px 0px 6px #0000000A; */
  background: transparent;
}

.page-view .action-holder .button-wrapper {
  width: 80px;
  text-align: center;
}

.page-view .action-holder button {
  padding: 10px 16px;
  background: #40BBF0;
  box-shadow: 0px 3px 6px #00000036;
  border-radius: 6px;
  transition: transform .1s;
  font-size: 18px;
  color: #FBFBFB;
  outline: none;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
}

.page-view .action-holder button:hover {
  transform: scale(1.1);
  font-weight: 600;
}
</style>