export default {
  namespaced: true,
    state: {},
  
    getters: {
      // message (state) {
      //   return state.message
      // },
      // type (state) {
      //   return state.type
      // }
    },
  
    mutations: {
      setMessage(state, data) {
        state = data;
      },

    },
  
    actions: {
    
     
      
    },
  }
    