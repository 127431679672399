<template>
    <div class="live-countdown" ref="line_main" v-if="eventStarted === false && live_event_data != null" :style="{backgroundColor:live_countdown_background}">      
        <div class="live-left" :style="{width:`${100}%`}">
            <div :style="{ 
                backgroundImage:`url(${checkData(live_event_data.live_event.event.image,live.backgroundImage)})`,
                hight: calculatedHeight+ 'px',
             }" 
             class="live-main"
             ref="cover_image" 
             >
                <div style="width:100%" ref="beforeLoad">test</div>
                <div class="live-title" :style="elementPositionStyles(checkData(live_event_data.organization.layout_data.title_position + 1,titlePosition))">
                    <h1 :style="{
                        color: `${checkData(live_event_data.organization.title_data.color,titleColor)}`,
                        // background: `${checkData(live_event_data.organization.title_data.background,titleBackground)}`,
                        // border: `${checkData(live_event_data.organization.title_data.border,titleBorder)}`,
                        // fontFamily: `${checkData(live_event_data.organization.title_data.font_family,titleFontFamily)}`
                    }"
                    >{{checkData(live_event_data.live_event.event.name,event_title)}}</h1>
                </div>
                <div class="live-timer" :style="elementPositionStyles(checkData(live_event_data.organization.layout_data.timer_position + 1,timerPosition))">                 
                    <Timer 
                    :liveEventStarted="liveEventStarted" 
                    :colors="checkData(live_event_data.organization.timer_data,timerColors)"
                    :startDate="checkData(live_event_data.live_event.event.start_date,timerStartDate)+'T'+checkData(live_event_data.live_event.event.start_time,timerStartTime)"
                    />
                </div>
            </div>
            <div class="live-information" v-if="description">
                <div class="live-description" >

                </div>
                <div class="live-icons">
                    <IconNotes v-if="shareLink != ''" />
                    <IconShare v-if="notes" />
                </div>
            </div>
            <div class="live_ad-1 live_ad" >
                <a :href="ads.pos1.link" ref="ad_1">
                    <img :src="ads.pos1.url" alt="">
                </a>
            </div>
        </div>
        <div v-if="layoutStyle === 1 || layoutStyle === 3" class="live-right">
            <div v-if="layoutStyle === 1" ref="ad_2" class="live_ad-2 live_ad" :style="{hight: calculatedHeight+ 'px',}"> 
                <a :href="ads.pos2.link" >
                    <img :src="ads.pos2.url"  alt="" >
                </a>
            </div>
            <div v-if="layoutStyle === 3" ref="ad_4" class="live_ad-4 live_ad">
                <a :href="ads.pos4.link" >
                    <img :src="ads.pos4.url" alt="">
                </a>
            </div>
            <div  v-if="layoutStyle === 1" ref="ad_3" class="live_ad-3 live_ad"> 
                <a :href="ads.pos3.link">
                    <img :src="ads.pos3.url" alt="">
                </a>
            </div>
        </div>
    </div>
    <JWPlayer 
      v-if="eventStarted === true"
      :liveUrl="live_event_data.live_event.event.image"
      :autostart="true"
      :nextUpDisplay="false"
      :width="1000"
      :height="562"
    />
</template>

<script >

    import { ref, onMounted} from 'vue';
    import axios from '../js/axios.js';

    import Timer from './Timer.vue';
    import IconNotes from '@/components/icons/IconNotes.vue';
    import IconShare from '@/components/icons/IconShare.vue';
    import JWPlayer from '@/components/JWPlayer.vue';

    export default {
        components:{
            Timer,
            IconNotes,
            IconShare,
            JWPlayer
        },

        data() {           
            return {
                live_countdown_background: 'green',
                layoutStyle: 1, 
                event_title:'Test title',
                ads:{
                    pos1:{
                        url:"http://www.vlifetech.com/img/destiny/seven/add-3.png",
                        link:"#",
                    },
                    pos2:{  
                    url:"http://www.vlifetech.com/img/destiny/seven/add-1.png",
                        link:"#",
                    },
                    pos3:{
                        url:"http://www.vlifetech.com/img/destiny/seven/add-2.png",
                        link:"#",
                    },
                    pos4:{
                        url:"http://www.vlifetech.com/img/destiny/seven/add-1.png",
                        link:"#",
                    },
                },
                live:{
                    backgroundImage:"http://www.vlifetech.com/img/media/SignsandWonders10.png"
                },
                timerPosition:3,
                titlePosition:1,
                shareLink:0,
                notes:false,
                eventStarted:false,
                live_event_data:null,
                titleColor:'#000',
                timerStartDate:'2023-09-30',
                timerColors:{
                    background: "rgba(23, 24, 38, 0.33)",
                    color: "rgba(255, 255, 255, 1)",
                    border_color: "rgba(0, 97, 255, 1)",
                },
                timerStartTime:'12:00:00'
            }
        },

        created(){
            this.getLiveLayout();
        },

        methods: {
            elementPositionStyles(position) {
                let styles = {
                    top: '0',
                    left:'0',
                };
                if (position === 1) {
                    styles.top = '0';
                    styles.left = '0';
                } else if (position === 2) {
                    styles.top = '50%';
                    styles.left = '50%';
                    styles.transform = `translate(-50%, -50%)`;
                } else if (position === 3) {
                    styles.top = 'unset'
                    styles.bottom = '0';
                    styles.left = '0';
                }
                else if (position  === 4) {
                    styles.top = '0'
                    styles.right = '0';
                    styles.left = 'unset';
                }

                return styles;  
            },

            liveEventStarted(started){
                if (started) {
                    this.eventStarted = started;
                    console.log('Main component reloaded');
                }
            },

            checkData(dataFromApi, defaultData){
                return dataFromApi ? dataFromApi : defaultData;
            },

            getLiveLayout(){axios.get(`v1/live/8/player-data`)
            .then(async response => {
                if(response.data.status === 'success') {
                    this.live_event_data = response.data.data
                    // const liveEventData = response.data.data;
                    // console.log(this,live_event);
                    // layoutStyle = liveEventData.organization.layout_data.countdown_style + 1;
                    // this.timerPosition = liveEventData.organization.layout_data.timer_position + 1;
                    // this.titlePosition = liveEventData.organization.layout_data.title_position + 1;
                    // this.live.backgroundImage = liveEventData.live_event.event.image;

                    // console.log(liveEventData.live_event.event.image, this.live.backgroundImage);
                } else {
                    console.log('no');
                }
            }).catch(error => {
                console.log(error);
                });
            }
        },

        setup() {
            const cover_image = ref(null);
            const ad_1 = ref(null);
            const ad_2 = ref(null);
            const ad_4 = ref(null);
            const calculatedHeight = ref(0);
            const beforeLoad = ref(null);
            // const layoutStyle = ref(1);

            const getWidth = () => {
                
                if (cover_image.value) {
                    const width = cover_image.value.offsetWidth; 
                    calculatedHeight.value = Math.floor(width / 1.78);
                }
                
            };

            const updateCoverImageHeight = () => {
                getWidth();
                if (cover_image.value) {
                    cover_image.value.style.height = calculatedHeight.value + 'px';
                }
                if (ad_2.value) {
                    ad_2.value.style.height = calculatedHeight.value + 'px';
                }
                if (ad_4.value) {
                    const ad_1_height = ad_1.value.offsetHeight ? ad_1.value.offsetHeight + 10 : 0;
                    ad_4.value.style.height = calculatedHeight.value + ad_1_height + 'px';
                }
            };


            onMounted(async() => {                
                const interval = setInterval(() => {
                    if (beforeLoad.value) {
                        updateCoverImageHeight();
                        clearInterval(interval);
                        beforeLoad.value.style.display='none'
                    }                 
                }, 1000);
            });

            return {
                cover_image,
                ad_1,
                ad_2,
                ad_4,
                calculatedHeight,
                updateCoverImageHeight,
                beforeLoad,
            };            
        }
    };

</script>

<style>
    .live-countdown{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        max-width: 1200px;
        gap: 10px;
        height: fit-content;
    }

    .live-right{
        width:20%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
    }

    .live-left{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap:10px;
        width: 100%;
    }
    
    .live-main{
        background-size: cover;
        background-repeat: no-repeat;
        width:100%;
        position: relative;
    }

    .live_ad{
        width: 100%;
        height: 100%; 
    }

    .live_ad-1,
    .live_ad-3{
        height: 90px;
    }

    .live_ad a{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    
    .live_ad a img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .live-title,.live-timer{
        position: absolute;
    }

    .live-title h1{
        /* color: red; */
        width: max-content;
        padding: 30px;
        line-height: 1;
    }

</style>