<template>
  <svg :width="iconSize" :height="iconSize" viewBox="0 0 18 15.363">
    <path id="Path_18" data-name="Path 18" d="M15.429,8.093v8.858H18V8.093Zm-5.143-6.5V16.951h2.571V1.588ZM5.143,5.977V16.951H7.714V5.977ZM0,10.367v6.584H2.571V10.367Z" transform="translate(0 -1.588)" :fill="iconColor"/>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>
