<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="iconSize" :height="iconSize" viewBox="0 0 8.203 6.905">
        <g id="Group_727" data-name="Group 727" transform="translate(-815.446 -1110.048)">
            <path id="Path_2665" data-name="Path 2665" d="M24,15.284l2.392-2.392L24,10.5" transform="translate(792.507 1100.608)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path id="Path_2666" data-name="Path 2666" d="M24,15.284l2.392-2.392L24,10.5" transform="translate(796.507 1100.608)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </g>
    </svg>

</template>

<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>
