<template>
  <div class="dropdown" v-if="options">
    <!-- Dropdown Input -->
    <input class="dropdown-input" :name="name" @focus="showOptions()" @blur="exit()" @keyup="keyMonitor"
      v-model="searchFilter" :disabled="disabled" :placeholder="placeholder" :required="required" autocomplete="off"
      ref="inputField" :class="{ 'readonly-input': !searchable }" />

    <!-- Dropdown Menu -->
    <div class="dropdown-content" v-show="optionsShown">
      <div class="dropdown-item" @mousedown="selectOption(option, $event)" v-for="(option, index) in filteredOptions"
        :key="index">
        {{ option.name || option.id || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';

export default {
  name: 'Dropdown',
  props: {
    initId: {
      type: Number,
      required: false,
      default: null,
    },
    initMedia: {
      type: Number,
      required: false,
      default: null,
    },
    defaultId: {
      type: Number,
      required: false,
      default: null,
    },
    defaultValueById: {
      type: Number,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name',
    },
    options: {
      type: Array,
      required: true,
      default: [],
      note: 'Options of dropdown. An array of options with id and name',
    },
    placeholder: {
      type: String,
      required: false,
      note: 'Placeholder of dropdown',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown',
    },
    maxItem: {
      type: Number,
      required: false,
      default: null,
      note: 'Max items showing',
    },
    id: Object
  },
  setup(props, { emit }) {
    const selected = ref({});
    const optionsShown = ref(false);
    const searchFilter = ref('Default');
    const inputField = ref(null);
    let isExternalUpdate = false;

    const defaultValueById = computed(() => props.defaultValueById);
    const defaultId = computed(() => props.defaultId);
    const initId = computed(() => props.initId);
    const initMedia = computed(() => props.initMedia);
    const id = computed(() => props.id);

    watch(defaultValueById, () => {
      setDefaultValueBasedOnId(defaultValueById.value);
    });
    watch(defaultId, () => {
      setDefaultId(defaultId.value);
    });
    watch(initId, () => {
      setInitId(initId.value);
    });
    watch(id, () => {
      setInitId(id.value);
    });
    watch(initMedia, () => {
      if (!isExternalUpdate) {
        setInitMedia(initMedia.value);
      }
      isExternalUpdate = false;
    });

    const setInitMedia = (id) => {
      if (id !== null) {
        const defaultOption = props.options.find(option => option.id === id);
        if (defaultOption) {
          selected.value = defaultOption;
          searchFilter.value = defaultOption.name;
        }
      } else if (id === null) {
        selected.value = {};
        searchFilter.value = '';
      }
    };

    const setInitId = (id) => {
      if (id !== null) {
        const defaultOption = props.options.find(option => option.id === id);
        if (defaultOption) {
          selected.value = defaultOption;
          searchFilter.value = defaultOption.name;
        }
      } else if (id === null) {
        selected.value = {};
        searchFilter.value = '';
      }
    };

    const setDefaultValueBasedOnId = (id) => {
      if (id !== null) {
        const defaultOption = props.options.find(option => option.id === id);
        if (defaultOption) {
          selected.value = defaultOption;
          searchFilter.value = defaultOption.name;
        }
      } else if (id === null) {
        selected.value = {};
        searchFilter.value = '';
      }
    };

    const setDefaultId = (id) => {
      if (id !== null) {
        const defaultOption = props.options.find(option => option.id === id);
        if (defaultOption) {
          selected.value = defaultOption;
          searchFilter.value = defaultOption.name;
        }
      } else if (id === null) {
        selected.value = {};
        searchFilter.value = '';
      }
    };

    setDefaultValueBasedOnId(defaultValueById.value);
    setDefaultId(defaultId.value);
    setInitId(initId.value);
    setInitMedia(initMedia.value);

    const filteredOptions = computed(() => {
      const filtered = [];
      const regOption = new RegExp(searchFilter.value, 'ig');
      for (const option of props.options) {
        if (searchFilter.value.length < 1 || option.name.match(regOption)) {
          filtered.length < props.maxItem || !props.maxItem ? filtered.push(option) : null;
        }
      }
      return filtered;
    });

    const selectOption = (option, event) => {
      isExternalUpdate = true;
      selected.value = option;
      optionsShown.value = false;
      searchFilter.value = selected.value.name;
      emit('selected', selected.value);
    };

    const showOptions = () => {
      if (!props.disabled) {
        searchFilter.value = '';
        optionsShown.value = true;
      }
    };

    const exit = () => {
      console.log(selected.value);
      
      if (!selected.value.id) {
        if (!props.searchable) {
          selected.value = {};
          searchFilter.value = '';
        }
      } else {
        searchFilter.value = selected.value.name;
      }

      emit('selected', selected.value);
      optionsShown.value = false;
    };

    const keyMonitor = (event) => {
      if (event.key === 'Enter' && filteredOptions.value[0]) {
        selectOption(filteredOptions.value[0]);
      }
    };

    const blockInput = (event) => {
      event.preventDefault();
      return false;
    };

    onMounted(() => {
      if (!props.searchable) {
        inputField.value.addEventListener('keydown', blockInput);
        inputField.value.addEventListener('click', blockInput);
      }
      if (defaultValueById.value) {
        setDefaultValueBasedOnId(defaultValueById.value);
      }
      if (initId.value) {
        setInitId(initId.value);
      }
      if (initMedia.value) {
        setInitMedia(initMedia.value);
      }
    });

    onBeforeUnmount(() => {
      if (!props.searchable) {
        inputField.value.removeEventListener('keydown', blockInput);
        inputField.value.removeEventListener('click', blockInput);
      }
    });

    const selectNextOption = () => {
      if (!optionsShown.value) {
        showOptions();
      } else {
        const selectedIndex = filteredOptions.value.indexOf(selected.value);
        const nextIndex = selectedIndex === -1 ? 0 : selectedIndex + 1;
        selected.value = filteredOptions.value[nextIndex % filteredOptions.value.length];
      }
    };

    const selectPreviousOption = () => {
      if (!optionsShown.value) {
        showOptions();
      } else {
        const selectedIndex = filteredOptions.value.indexOf(selected.value);
        const prevIndex = selectedIndex === -1 ? 0 : selectedIndex - 1;
        selected.value = filteredOptions.value[(prevIndex + filteredOptions.value.length) % filteredOptions.value.length];
      }
    };

    const selectCurrentOption = () => {
      if (optionsShown.value && selected.value) {
        selectOption(selected.value);
      }
    };

    watch(searchFilter, () => {
      emit('filter', searchFilter.value);
    });

    return {
      inputField,
      selected,
      optionsShown,
      searchFilter,
      filteredOptions,
      selectOption,
      showOptions,
      exit,
      keyMonitor,
      selectNextOption,
      selectPreviousOption,
      selectCurrentOption,
    };
  },
};
</script>

<style scoped>
.readonly-input {
  caret-color: transparent;
  cursor: not-allowed;
}

.dropdown {
  position: relative;
  width: 100%;
  display: block;
}

.dropdown-input {
  width: 100%;
  background: #fff;
  cursor: pointer;
  color: #333;
  display: block;
  padding: 8px 12px;
  outline: none;
  border-radius: 4px;
  box-shadow: 0px 0px 6px #0000000A;
  border: 0.5px solid #ECECEC;
  font-size: 12px;
  height: 25px;
}

.dropdown-item:hover {
  background: #f8f8fa;
}

.dropdown-content {
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  max-height: 248px;
  border: 1px solid #e7ecf5;
  box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
  overflow: auto;
  z-index: 5000;
}

.dropdown-item {
  color: #464545;
  font-size: 13px;
  line-height: 1em;
  padding: 8px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
</style>
