<template>
    <header v-if="this.logo">
        <!-- Desktop Header -->
        <div class="desktop-header">
            <div>
                <div class="header-logo">
                    <router-link 
                        :to="{ name: '.organizationName', params: { organizationName: this?.organizationName } }">
                        <img :src="$azureUrl + this.logo" alt="logo">
                    </router-link>
                </div>
            </div>

            <nav>
                <ul>
                    <li v-if="store.state.playerData?.layout_data?.show_navigation_links && store.state.playerData?.layout_data?.show_navigation_links?.schedule_24_7">
                        <router-link :to="{ name: '.layout-24-7', params: { organizationName: this?.organizationName } }">24/7</router-link>
                    </li>
                    <li v-if="store.state.playerData?.layout_data?.show_navigation_links && store.state.playerData?.layout_data?.show_navigation_links?.live">
                        <router-link :to="{ name: '.live', params: { organizationName: this?.organizationName, eventId: this?.eventId } }">live</router-link>
                    </li>
                    <!-- <li v-if="allCategories && allCategories.length && allCategories.some(category => category.media_count || category.has_subcategories)"> -->
                    <li v-if="store.state.playerData?.layout_data?.show_navigation_links && store.state.playerData?.layout_data?.show_navigation_links?.on_demand">
                        <router-link :to="{ name: '.videos', params: { organizationName: this?.organizationName } }">on demand</router-link>
                    </li>
                </ul>
            </nav>
        </div>

        <!-- Mobile Header -->
        <div class="mobile-header">
            <div class="header-logo">
                <router-link 
                    :to="{ name: '.organizationName', params: { organizationName: this?.organizationName, eventId: this?.eventId } }">
                    <img :src="$azureUrl + this.logo" alt="logo">
                </router-link>
            </div>

            <div class="open-mobile-menu-btn-holder">
                <button class="open-mobile-menu-btn" @click="showMenu = true"><IconMobileMenuDots/></button>
            </div>
        </div>

        <!-- Mobile Menu -->
        <div v-show="showMenu" class="mobile-menu" :class="{ open: showMenu }">
            <div class="head">
                <button class="close-mobile-menu-btn" @click="showMenu = false"><font-awesome-icon :icon="['fas', 'xmark']" /></button>
            </div>

            <nav @click="hideMenu">
                <ul>
                    <li>
                        <router-link :to="{ name: '.organizationName', params: { organizationName: this?.organizationName, eventId: this?.eventId } }" v-slot="{ isActive }"><IconHome :iconColor="isActive ? 'var(--nav-color)' : '#fff'" :iconSize="22"/></router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: '.featured', params: { organizationName: this?.organizationName } }">Featured</router-link>
                    </li>
                    <li v-if="store.state.playerData?.layout_data?.show_navigation_links && store.state.playerData?.layout_data?.show_navigation_links?.live">
                        <router-link :to="{ name: '.live', params: { organizationName: this?.organizationName, eventId: this?.eventId } }">Live</router-link>
                    </li>
                    <li v-if="store.state.playerData?.layout_data?.show_navigation_links && store.state.playerData?.layout_data?.show_navigation_links?.schedule_24_7">
                        <router-link :to="{ name: '.layout-24-7', params: { organizationName: this?.organizationName } }">24 / 7 playlist</router-link>
                    </li>
                    <li v-if="store.state.playerData?.layout_data?.show_navigation_links && store.state.playerData?.layout_data?.show_navigation_links?.on_demand && allCategories && allCategories.length && allCategories.some(category => category.media_count || category.has_subcategories)" class="drop" :class="{ open: showOnDemand, closed: !showOnDemand }">
                        <span @click="showOnDemand = !showOnDemand"><font-awesome-icon :icon="['fas', 'chevron-down']" /> On Demand</span>
                        <ul v-if="showOnDemand">
                            <template v-for="category in allCategories">
                                <li v-if="category.media_count || category.has_subcategories">
                                    <router-link 
                                        :to="$getCategoryPath(category.slug, 1, false)">{{ category.name }}
                                    </router-link>
                                </li>
                            </template>
                        </ul>
                    </li>
                    <li>
                        <router-link @click="" :to="{ name: '.videos', params: { organizationName: this?.organizationName } }">Search</router-link>
                    </li>
                    <li v-if="organization?.url_data?.donation">
                        <a :href="organization.url_data.donation" target="_blank">Donation</a>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
import { useStore } from 'vuex';
import layoutsMixin from '@/mixins/layouts.vue';

import IconMobileMenuDots from '@/components/icons/IconMobileMenuDots.vue';
import IconHome from '@/components/icons/IconHome.vue';

export default {
    components: {
        IconMobileMenuDots,
        IconHome
    },
    mixins: [],
    setup() {
        const store = useStore();

        return {
            store
        }
    },
    data() {
        return {
            organizationName: null,
            showMenu: false,
            showOnDemand: true
        }
    },
    mixins: [ layoutsMixin ],
    computed: {
        logo() {
            return this.store.state.playerData?.image_data.logo;
        },
        allCategories() {
            return this.store.state.onDemandCategories;
        },
    },
    props: {
        organization: {
            type: Object
        },
        organizationSlug: {
            type: String
        },
        eventId: {
            type: String
        }
    },
    methods: {
        hideMenu(e) {
            if (e.target.tagName === 'A' || (e.target.parentElement && e.target.parentElement.tagName === 'A')) {
                this.showMenu = false;
            }
        },
        async getLive() {
            try {
                this.eventData = await this.getLiveEvent(this.organizationSlug, this.eventId);
                this.store.commit('setLiveEvent', this.eventData);
            } catch (error) {
                throw error;
            }
        }
    },
    watch: {},
    mounted() {
        // this.getLive();
    }
}
</script>

<style scoped>
header {
    width: 100%;
}

header .header-logo>a {
    display: block;
    width: 100%;
    height: 100%;
}

header .header-logo>a>img {
    height: 100%;
    max-height: 70px;
    object-fit: cover;
}

/* #region Desktop Header */
header .desktop-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;
    padding: 15px 3rem;
}

header .desktop-header h3 {
    font-size: 33px;
    font-weight: 600;
    color: #46454542;
}

header .desktop-header nav {
    display: flex;
    justify-content: flex-end;
}

header .desktop-header ul {
    display: flex;
    gap: 25px;
    align-items: flex-end;
    list-style: none;
}

header .desktop-header ul li {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 21px;
    cursor: pointer;
    font-weight: 800;
}

header .desktop-header ul li a {
    text-decoration: none;
    font-weight: 800;
    color: var(--nav-color);
    font-family: var(--header-font);
}
/* #endregion Desktop Header */

/* #region Mobile Header */
header .mobile-header {
    position: relative;
    display: none;
    justify-content: center;
    padding: 15px;
}

header .mobile-header .header-logo {
    max-width: 120px;
}

header .open-mobile-menu-btn-holder {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}

header .open-mobile-menu-btn-holder .open-mobile-menu-btn {
    border: none;
    outline: none;
    background-color: transparent;
}

header .open-mobile-menu-btn-holder .open-mobile-menu-btn svg {
    max-height: 35px;
}
/* #endregion Mobile Header */

/* #region Mobile Menu */
.mobile-menu * {
    font-family: 'Century Gothic';
}

.mobile-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    background: #02020275;
    backdrop-filter: blur(14px);
    z-index: 99;
}

.mobile-menu.open {
    opacity: 1;
}

.mobile-menu .head button {
    float: right;
    background-color: transparent;
    border: none;
    outline: none;
    color: #FFF;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
}

.mobile-menu ul {
    list-style: none;
    font-size: 21px;
    text-transform: uppercase;
    color: #FFF;
    padding-left: 24px;
}

.mobile-menu ul .open,
.mobile-menu ul .active,
.mobile-menu ul a:hover {
    color: var(--nav-color);
}

.mobile-menu ul li span {
    margin-left: -25px;
}


.mobile-menu ul li span svg {
    font-size: 18px;
    transition: transform 0.4s ease;
}

.mobile-menu ul li.closed span svg {
    transform: rotate(180deg);
}

.mobile-menu ul .open a {
    font-size: 18px;
    text-transform: none;
}

.mobile-menu ul a {
    text-decoration: none;
    color: #FFF;
}

.mobile-menu ul a.router-link-active {
    color: var(--nav-color);
}
/* #endregion Mobile Menu */

/* #region Responsive */
@media screen and (max-width: 1366px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 850px) {}

@media screen and (max-width: 600px) {
    header .desktop-header {
        display: none;
    }

    header .mobile-header {
        display: flex;
    }
}
/* #endregion Responsive */
</style>